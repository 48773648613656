// src/msalInstance.js
import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID, // .env ファイルから取得
    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI, // .env ファイルから取得
    authority: 'https://login.microsoftonline.com/common' // 必要に応じて変更
  }
};

const msalInstance = new PublicClientApplication(msalConfig);


// 初期化を待つ関数を定義
async function initializeMsalInstance() {
  await msalInstance.initialize();
  console.log('MSAL Instance Initialized:', msalInstance);
}
initializeMsalInstance();

// console.log('clientId:', process.env.VUE_APP_AZURE_CLIENT_ID);
// console.log('redirectUri:', process.env.VUE_APP_AZURE_REDIRECT_URI);

// console.log('msalInstance:', msalInstance);

export default msalInstance;

