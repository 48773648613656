
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css' // Element Plusのスタイル

import vuetify from '@/plugins/vuetify'; // 新しく追加

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import 'resize-observer-polyfill';
import 'ag-grid-enterprise';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';


ModuleRegistry.registerModules([ FiltersToolPanelModule ]);
ModuleRegistry.registerModules([ SideBarModule ]);
ModuleRegistry.registerModules([RowGroupingModule]);
ModuleRegistry.registerModules([ServerSideRowModelModule]);
ModuleRegistry.registerModules([MultiFilterModule]);
ModuleRegistry.registerModules([SetFilterModule]);
ModuleRegistry.registerModules([RichSelectModule]);
ModuleRegistry.registerModules([MenuModule]);
// LicenseManager.setLicenseKey("[TRIAL]_this_AG_Grid_Enterprise_key_( AG-048514 )_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_purchasing_a_production_key_please_contact_( info@ag-grid.com )___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_( 30 November 2023 )____[v2]_MTcwMTMwMjQwMDAwMA==3a47625d2e0529ecda3d7731f0725d5f");
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-051373}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Accrual_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{accrualApp}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{accrualApp}_need_to_be_licensed___{accrualApp}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_December_2024}____[v3]_[01]_MTczNTM0NDAwMDAwMA==ee3022d8664e53b44b9629278d0c8ce2");

ModuleRegistry.registerModules([RangeSelectionModule]);
ModuleRegistry.registerModules([ClipboardModule]);


const app = createApp(App);

app.use(router);
app.use(vuetify);
app.use(store);
app.mount('#app');
app.use(Toast, {/* オプション */});
app.use(ElementPlus);
// createApp(App).use(router).use(vuetify).mount('#app')