<template>
    <v-app style="background-color: #d0d0d0;">
      <router-view></router-view>
    </v-app>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.startInactivityTimer();
    // ユーザーのアクティビティを監視
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keydown', this.resetInactivityTimer);
  },
  beforeUnmount() {  // beforeDestroy の代わりに beforeUnmount を使用
    // イベントリスナーを削除
    window.removeEventListener('mousemove', this.resetInactivityTimer);
    window.removeEventListener('keydown', this.resetInactivityTimer);
  },
  methods: {
    startInactivityTimer() {
      this.inactivityTimeout = setTimeout(() => {
        this.$store.dispatch('logoutUser'); // 10分間操作がなければログアウト
      }, 20 * 60 * 1000); // 20分（10 * 60 * 1000ミリ秒）
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout); // 現在のタイマーをクリア
      this.startInactivityTimer(); // タイマーをリセット
    }
  }
}
</script>
