<template>
  <div class="signup-container">
    <br>
    <h2>サインイン</h2>
    <br>
    <p>新規アカウントを作成するには、以下のいずれかのサービスログインを使用してください。</p>
    <br>
    
    <!-- Googleサインインボタン -->
    <div id="g_id_onload"
         :data-client_id="googleClientId"
         :data-login_uri="googleLoginUri"
         data-auto_prompt="false">
    </div>
    <div id="g_id_signin" class="login-button"></div>
    <br>
    <!-- Microsoftサインインボタン -->
    <div class="login-button">
      <button @click="loginWithMicrosoft" class="ms-login-button">
        <img src="@/assets/microsoft-logo.png" alt="Microsoft logo" class="ms-logo" />
        Microsoftでログイン
      </button>
    </div>
    <br>
    <!-- 戻るボタン -->
    <button @click="goBack" class="back-link">戻る</button>
  </div>
</template>

<script>
import msalInstance from '@/msalInstance';

export default {
  data() {
    return {
      googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      googleLoginUri: process.env.VUE_APP_GOOGLE_LOGIN_URI,
      azureClientId: process.env.VUE_APP_AZURE_CLIENT_ID,
      azureRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI,
    };
  },
  methods: {
    login() {
      // 既存のログインロジック
    },
    goBack() {
      this.$router.push({ name: 'LoginSignupChoice' });
    },
    submitForm() {
      const mode = this.$route.query.mode || 'login';
      this.$store.dispatch('login', { credentials: this.credentials, mode });
    },
    initializeGoogleSignIn() {
      let retryCount = 0;
      const maxRetries = 10; // 最大リトライ回数
      const retryInterval = 500; // チェック間隔（ミリ秒）

      const checkGoogleApiLoaded = () => {
        const googleSignInElement = document.getElementById('g_id_signin');
        if (typeof google !== 'undefined' && googleSignInElement) {
          // Google API がロードされ、要素が存在している場合
          google.accounts.id.initialize({
            client_id: this.googleClientId,
            callback: this.handleCredentialResponse
          });
          google.accounts.id.renderButton(
            googleSignInElement,
            { theme: 'outline', size: 'large', width: 280, text: 'signin_with', logo_alignment: 'left' }
          );
          clearInterval(intervalId); // タイマーをクリアして繰り返し処理を停止
        } else {
          retryCount++;
          if (retryCount >= maxRetries) {
            clearInterval(intervalId); // タイマーをクリアして処理を停止
            console.error('Google API または要素がロードされませんでした。最大リトライ回数に達しました。');
          }
        }
      };

      // Google API がロードされているか、要素が存在するかを確認し、ロードされていない場合は再試行
      const intervalId = setInterval(checkGoogleApiLoaded, retryInterval);
    },

    handleCredentialResponse(response) {
      
      fetch('/api/authenticate.php', { // あなたのサーバーエンドポイントに置き換えてください
          method: 'POST',
          headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token: response.credential, provider: 'google' }) // providerを追加
      })
      .then(response => {
          if (response.ok) {
          return response.json();
          }
          throw new Error('Network response was not ok.');
      })
      .then(data => {
          let targetCompanyFormatted;
          if (Array.isArray(data.targetCompany) && data.targetCompany.length === 1) {
              targetCompanyFormatted = data.targetCompany[0];
          } else {
              targetCompanyFormatted = '会社選択';
          }

          this.$store.commit('setLoginDetails', {
              username: data.username, // サーバーから受け取ったユーザー名
              email: data.email, // サーバーから受け取ったemail
              targetCompany: targetCompanyFormatted, // 調整されたtargetCompanyの値
              targetPeriod: '' // 必要に応じて設定
          });
          this.$store.commit('login');
          this.$router.push({ name: 'Menu' });  // 例: this.$router.push({ name: 'Home' });
      })
      .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
          this.$router.push({ name: 'LoginFailed' });  // 例: this.$router.push({ name: 'Home' });
      });
    },
    async loginWithMicrosoft() {
      try {
          const loginRequest = { scopes: ["User.Read"] };
          console.log('Attempting to login with Microsoft...');

          // Microsoft にログインし、トークンを取得
          const response = await msalInstance.loginPopup(loginRequest);
          console.log('Microsoft Login Response:', response);

          // Microsoft Graph API からユーザー情報を取得
          const userInfoResponse = await fetch("https://graph.microsoft.com/v1.0/me", {
              headers: {
                  Authorization: `Bearer ${response.accessToken}`,
              },
          });

          if (!userInfoResponse.ok) {
              throw new Error('Failed to fetch Microsoft user information');
          }

          const userData = await userInfoResponse.json();
          console.log('Microsoft User Data:', userData);

          // ユーザー情報をバックエンドに送信
          const authResult = await this.authenticateWithServer(userData, 'microsoft');
          console.log('Authentication Result:', authResult);
          this.handleSuccessfulLogin(authResult);
      } catch (error) {
          console.error('Microsoftログインに問題がありました:', error);
          this.$router.push({ name: 'LoginFailed' });
      }
  },
  async authenticateWithServer(userData, provider) {
      const response = await fetch('/api/authenticate.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userData, provider })
      });

      if (!response.ok) {
          throw new Error('サーバー認証に失敗しました');
      }

      return response.json();
  },

  handleSuccessfulLogin(data) {
      const targetCompanyFormatted = Array.isArray(data.targetCompany) && data.targetCompany.length === 1
        ? data.targetCompany[0]
        : '会社選択';

      this.$store.commit('setLoginDetails', {
        username: data.username,
        email: data.email,
        targetCompany: targetCompanyFormatted,
        targetPeriod: ''
      });
      this.$store.commit('login');
      this.$router.push({ name: 'Menu' });
    },
  },
  created() {
    console.log('msalInstance in created:', msalInstance); // ここで undefined でないことを確認
    this.mode = this.$route.query.mode || 'login';
  },
  mounted() {
    // 既存のGoogleログイン初期化の呼び出し
    this.initializeGoogleSignIn();

    if (typeof google !== 'undefined') {
      google.accounts.id.renderButton(
        document.getElementById('g_id_signin'),
        { 
          theme: 'outline', 
          size: 'large', 
          width: 280,
          text: 'signin_with',
          logo_alignment: 'left'
        }
      );
    } else {
      console.error('Google API がロードされていません。');
    }
  }
}
</script>

<style>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/* Microsoft ログインボタンのスタイルを Google に合わせる */
.ms-login-button {
  display: flex;
  align-items: center;
  justify-content: center; /* 中央寄せにする */
  width: 280px;
  height: 50px; /* Google ボタンと同じ高さに設定 */
  padding: 0 12px; /* 左右のパディングを Google に合わせる */
  margin-bottom: 10px;
  border: 1px solid #dcdcdc; /* Google ボタンと似た色の境界線 */
  border-radius: 4px;
  cursor: pointer;
  background-color: #ffffff; /* Google ボタンと同様の白い背景 */
  font-size: 16px; /* Google ボタンと同じフォントサイズ */
  color: #5f6368; /* Google ボタンと似た色のテキストカラー */
  font-family: Arial, sans-serif; /* Google ボタンに合わせたフォント */
  gap: 12px; /* ロゴとテキストの間のスペースを調整 */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); /* Google ボタンと似た影 */
}

.ms-login-button:hover {
  background-color: #f7f7f7; /* Google ボタンのホバー色に合わせる */
}

/* Microsoft ロゴのスタイル */
.ms-login-button img.ms-logo {
  width: 20px; /* Google ロゴと同じくらいのサイズに調整 */
  height: 20px;
}
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* 上寄せに変更 */
  min-height: 100vh;
  padding: 40px 20px; /* 上部のパディングを増やして、少し下げる */
  box-sizing: border-box;
}

/* 既存のスタイル */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.back-link {
  margin-top: 20px;
  color: #007bff; /* 青色に変更 */
  text-decoration: none;
  cursor: pointer;
}

.back-link:hover {
  text-decoration: underline;
}
</style>