import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/dist/vuetify.min.css';

const vuetify = createVuetify({
    locale: {
        current: 'en', // デフォルト言語に設定
    },
    components,
    directives,
    theme: {
        themes: {
            light: {
                primary: "#ff0000",
                secondary: "#76c1c0", // 明るい緑色を直接指定
                accent: "#ff6f61", // 明るい赤色を直接指定
                error: "#f44336",
                warning: "#ffeb3b",
                info: "#2196f3",
                success: "#795548",
                mycolor: "#e0e0e0",
            },
            dark: {
                primary: "#ff0000",
                secondary: "#76c1c0", // 明るい緑色を直接指定
            },
        },
    },
});

export default vuetify;
