import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import HomePage from '@/components/HomePage.vue';
import LoginFailedPage from '@/components/LoginFailedPage.vue';
import MenuComponent from '@/components/MenuComponent.vue';
import LoginSignupChoice from '@/components/LoginSignupChoice.vue';
import SignupPage from '@/components/SignupPage.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'LoginSignupChoice',
    component: LoginSignupChoice,
    // meta: { requiresAuth: true }  // このルートは認証が必要です
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupPage
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true }  // このルートは認証が必要です
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/login-failed',
    name: 'LoginFailed',
    component: LoginFailedPage
  },
  {
    path: '/menu',
    name: 'Menu',
    component: MenuComponent,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// ナビゲーションガード
router.beforeEach((to, from, next) => {
  // 認証が必要ない場合はチェックをスキップ
  if (to.matched.some(record => record.meta.requiresAuth === false)) {
    next();
    return;
  }

  const isLoggedIn = store.getters.isAuthenticated;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next({ name: 'LoginSignupChoice' });
    } else {
      next();
    }
  } else if (isLoggedIn && (to.name === 'LoginSignupChoice' || to.name === 'Login')) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;
