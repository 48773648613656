<template>
    <div>
      <MenuComponent />
      <!-- 他のテンプレート部分 -->
    </div>
  </template>
  
  <script>
  import MenuComponent from '@/components/MenuComponent.vue';
  
  export default {
    components: {
      MenuComponent,
    },
    // 他のロジック
  };
  </script>
  