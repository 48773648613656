<!-- LoginFailedPage.vue -->
<template>
  <div>
    <h2>Login Failed</h2>
    <p>Incorrect username or password.</p>
    <router-link to="/login">Try again</router-link>
  </div>
</template>

<script>
export default {
  name: 'LoginFailedPage'
}
</script>

